import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

const SmartImg = (props) => {
  const {fluid,image, alt="",...rest} = props
  rest.image = image || fluid
  // console.log(rest)
  
  return <GatsbyImage
    {...rest}
    alt={alt}
    />
};

export default SmartImg;

// placeholder={{
//   // The placeholder looks much nicer with the blur effect
//   filter: `blur(20px)`,
//   // If image has transparency, through it you can see the placeholder
//   // suddenly disappearing in a ugly way: this transition solves it
//   transition: `opacity 500ms ease 0s`,
// }}